import React, { Component } from 'react';
import axios                from 'axios'
import Title                from '../Layout/Title';
import './Blog.css';


export default class Post extends Component {
    state = {
        post: {
            id: 0,
            title: "",
            content: "",
            created_at: ""
        },
        title: "tail -f /var/log/kope.log"
    }
    Post() {
    }

    componentDidMount() {
        const obtenerData = async() => {
            var baseUrl = (window.location).href
            var post_id = baseUrl.substring(baseUrl.lastIndexOf('/') + 1);
            const url = 'https://api.kope.cl/getPostById/'+post_id;
            await axios.get( url )
                .then( (response) => {
                    this.setState({
                        "post": response.data.data.post
                    });
                })
                .catch( error => {
                    console.log("=( NO conecta con API");
                    console.log(error);
                });
        }
        obtenerData();
    }

    render() {
        return(
            <div>
                <div className="">
                    <Title dataFromParent={this.state.title}/>
                    <div>
                        {
                            <div key={this.state.post.id} className="bg-opacity-90 bg-gray-900 blborder border-gray-900 text-gray-300 p-6 rounded-lg shadow-lg  max-w-2xl mx-auto min-h-screengPost">
                                <div className="postTitle p-6">{this.state.post.title}</div>
                                <div className='w-full h-1'></div>
                                <div className="postContent p-2" dangerouslySetInnerHTML={{ __html: this.state.post.content }}></div>
                                <div className='postDate p-2'>{this.state.post.created_at}</div>
                            </div>
                        }
                    </div>
                    
                </div>
                <div className='w-full h-4'></div>
            </div>
        )
    }

}