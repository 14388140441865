import React, { Component } from "react";
import './ErrorNotFound.css';

export default class ErrorNotFound extends Component {
    state = {
        text: "404: Página no encontrada"
    }

    render (){
        return (
            <div className="error404Container min-h-screen">
                <div className="errorText">{this.state.text}</div>
            </div>
        )
    }
}
