import React, { Component } from 'react';

import './Header.css';
import axios from "axios";

export default class Header extends Component {
    state = {
        located: " ",
        listening: " ",
        reading: " ",
        currentImageIndex: 0
    }

    sleepImage = 10;

    constructor(props) {
        super(props);
        this.state = {
            currentImageIndex: 0
        };
        this.images = [
            "/images/avatar-transparente.png",
            "/images/kp_moica.png",
            "/images/kp-Linkedin.jpeg",
            "./images/kp-sonda.jpg",
            "/images/kp-ren-martin.jpg",
            "/images/kp-phi-ren-tokio.jpg",
            "/images/kp-VLN.jpg",
            "/images/kp-south-park-avatar.png",
            "/images/kp-baru.jpg",
            "/images/kp-chupe.jpg",
            "/images/kp-25c.jpg",
            "/images/kp-armadaFrancesa.jpg",
            "/images/kp-f1.jpg",
            "/images/kp-kodokan.jpg",
            "/images/kp-1er-dan.jpg",
            "/images/kp-ren-casakano.jpg",
            "/images/kp-judo.jpg"
        ];
    }
    
    componentDidMount() {
        this.intervalImageKp = setInterval(() => {
            this.setState((prevState) => ({
                currentImageIndex: (prevState.currentImageIndex + 1) % this.images.length
            }));
        }, this.sleepImage *1000);

        const obtenerData = async() => {
            const url = 'https://api.kope.cl/getPageCover';

            await axios.get( url )
                .then( (response) => {
                    this.setState({
                        located: response.data.data.located,
                        reading: response.data.data.reading,
                        listening: response.data.data.listening
                    });
                })
                .catch( ()=>{
                    console.log("NO funciona =(");
                });
        }
        obtenerData();
    }

    componentWillUnmount() {
        clearInterval(this.intervalImageKp);
    }

    render () {
        return (
        <div className="header container mx-auto p-6 min-h-screen">
            <div className="bg-opacity-90 bg-gray-900 border border-gray-700 text-gray-300 p-6 rounded-lg shadow-lg max-w-lg mx-auto">  
                {/* Imagen de usuario */}
                <div className="flex justify-center">
                    <img
                        className="rounded-full w-32 h-32 border-4 border-gray-700 shadow-md"
                        src={this.images[this.state.currentImageIndex]}
                        alt="Avatar"
                    />
                </div>
                {/* Información */}
                <div className="mt-6">
                    <div className="text-left mb-4">
                        <h2 className="text-lg font-semibold text-gray-200">Ubicación:</h2>
                        <p className="italic text-sm text-gray-400 text-left">{this.state.located}</p>
                    </div>
                    <div className="text-left mb-4">
                        <h2 className="text-lg font-semibold text-gray-200">Leyendo:</h2>
                        <p className="italic text-sm text-gray-400">{this.state.reading}</p>
                    </div>
                    <div className="text-left">
                        <h2 className="text-lg font-semibold text-gray-200">Escuchando:</h2>
                        <p className="italic text-sm text-gray-400">{this.state.listening}</p>
                    </div>
                </div>
            </div>
        </div>
        
        )
    }
}
