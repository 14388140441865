import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios'

export default class FooterQuote extends Component {
    state = {
        ipv4: "127.0.0.1",
        city: "Ciudad",
        country: "Pais",
        text: "",
        author: ""
    }

    componentDidMount() {
        const obtenerDataQuotes = async() => {
			const url = 'https://api.kope.cl/getQuote';
			await axios.get( url )
				.then( (response) => {
					this.setState({
						text: "\"" + response.data.data.quote_text + "\"",
						author: "-- " + response.data.data.quote_author
					});
				})
				.catch( () => {
					console.log("NO funciona =(");
				});
		}
		obtenerDataQuotes();
    }
    render() {
        return (
        <div>
            <Link 
                to="/quotes" 
                className=""
                onClick={(e) => {
                    e.preventDefault();
                    window.location.href='/quotes';
                }}
                >
                <div className="footer bg-gray-800 text-white">
                    <div className="px-4 py-1">
                        {/* Columna derecha */}
                        <div className="text-center">
                            <p className="italic text-ms text-gray-400">{this.state.text}</p>
                            <p className="text-ms text-right gray-300">{this.state.author}</p>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
        );
    }
}
