import React, { Component } from "react";
import axios from "axios";
import "./Album.css";
import Title from "../Layout/Title";

export default class Album extends Component {
  state = {
    title: "",
    photos: [],
    album: {},
    currentPhotoIndex: null, // Índice de la foto actualmente visible en el modal
  };

  obtenerData = async () => {
    const url = "https://api.kope.cl/getPhotosByAlbumWithToken";
    try {
      localStorage.setItem("restrictedPath", window.location.href);
      var baseUrl = window.location.href;
      var album_id = baseUrl.substring(baseUrl.lastIndexOf("/") + 1);
      const response = await axios.post(url, {
        token: localStorage.getItem("authToken"),
        album_id: album_id,
      });
      console.log(response);
      if (response.data.code !== 200) {
        localStorage.removeItem("authToken");
        window.location.href = "/login";
      }

      this.setState({
        album: response.data.data.album,
        photos: Object.values(response.data.data.photos),
      });
    } catch (error) {
      console.error("Error al obtener los datos: ", error);
    }
  };

  componentDidMount() {
    (async () => {
      await this.obtenerData();
    })();
  }

  showModal = (index) => {
    const modal = document.getElementById("modal");
    const modalImg = document.getElementById("modal-img");
    const modalDescription = document.getElementById("modal-description");

    const { photos } = this.state;
    modal.classList.remove("hidden");
    modalImg.src = photos[index].url;
    modalDescription.innerText = photos[index].description;

    this.setState({ currentPhotoIndex: index });
  };

  closeModal = (e) => {
    e.preventDefault();
    const modal = document.getElementById("modal");
    modal.classList.add("hidden");
    this.setState({ currentPhotoIndex: null });
  };

  nextImage = () => {
    const { currentPhotoIndex, photos } = this.state;
    const newIndex = (currentPhotoIndex + 1) % photos.length;
    this.showModal(newIndex);
  };

  prevImage = () => {
    const { currentPhotoIndex, photos } = this.state;
    const newIndex =
      (currentPhotoIndex - 1 + photos.length) % photos.length;
    this.showModal(newIndex);
  };

  render() {
    return (
      <div>
        <Title dataFromParent={this.state.album.name} />
        <div className="photosContainer">
          <div className="descriptionContainer">
            <div className="descriptionText text-right bg-gray-900 border border-gray-900 text-gray-300 bg-opacity-90">
              {this.state.album.description}
            </div>
          </div>

          <section className="overflow-hidden text-gray-700 ">
            <div className="container px-5 py-2 mx-auto lg:pt-12 lg:px-32">
              <div className="flex flex-wrap -m-1 md:-m-2">
                {this.state.photos.map((photo, index) => (
                  <div className="flex flex-wrap w-1/3" key={photo.id}>
                    <div className="w-full p-1 md:p-2">
                      <img
                        alt="gallery"
                        className="block object-cover object-center w-full h-full rounded-lg hover:scale-105"
                        src={
                          "https://web.kope.cl/scripts/phpThumb.php?w=300&src=" +
                          photo.url
                        }
                        onClick={() => this.showModal(index)}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>

        {/* Modal */}
        <div
          id="modal"
          className="hidden fixed top-0 left-0 z-80 w-screen h-screen bg-black/70 flex justify-center items-center"
        >
          <a
            className="fixed z-80 top-6 right-8 text-white text-5xl font-bold"
            href="/#"
            onClick={(e) => this.closeModal(e)}
          >
            &times;
          </a>

          <button
            className="absolute left-4 text-white text-3xl"
            onClick={this.prevImage}
          >
            ⬅
          </button>

          <img
            id="modal-img"
            className="max-w-[800px] max-h-[600px] object-cover"
            alt=""
          />

          <button
            className="absolute right-4 text-white text-3xl"
            onClick={this.nextImage}
          >
            ➡
          </button>

          <div className="absolute flex justify-center items-center z-90 max-w-[600px] max-h-[400px]">
            <p
              className="text-2xl font-bold text-white text text-border justify-center"
              id="modal-description"
            >
              &nbsp;
            </p>
          </div>
        </div>
      </div>
    );
  }
}
