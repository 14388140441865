import './Who.css'
import Title from '../Layout/Title';

import React, { Component } from 'react';

export default class Who extends Component {

    state = {
        'title': '¿Qué es kope?'
    }

    render() {
        return (
            <div>
                <Title dataFromParent={this.state.title} />
                <div className="bg-opacity-90 bg-gray-900 border-gray-700 text-gray-300 p-6 rounded-lg shadow-lg max-w-lg mx-auto">
                    { /** Portada - títulos */ }
                    <div className="text-center">
                        <img 
                            className="rounded-full mx-auto w-32 border-4 border-gray-700"
                            src="https://web.kope.cl/images/kp-south-park-avatar.png" 
                            alt=""
                        />
                        <h1 className="text-xl font-semibold mt-4">Eduardo D. Peña Ceballos</h1>

                        <p className="text-m text-gray-400 mt-1">Papá de Renata desde 2013</p>
                    </div>
                    { /** Contenido */ }
                    <div className="mt-4 text-sm leading-relaxed text-justify">
                        <p className='p-1'>Tercer hijo de Ramón y Felisa. Nacido en Santiago de Chile en octubre de 1980. Criado en La Florida, pero con 14 mudanzas posteriores, es más fácil resumir y decir: <span className='italic'>vengo de Chile</span>.</p>

                        <p className='p-1'>Bombero voluntario Cuerpo de Bomberos de Santiago, orgulloso de pertenecer a la <span className='font-bold'>Cuarta Compañía Pompe France</span>. <span className='italic'>Honneur et Devoument</span>.</p>

                        <p className='p-1'>Hincha de <span className='font-bold'>Universidad de Chile</span>. Música de notas azules y rojas, la alegría de mi corazón.</p>

                        <p className='p-1'><span className='font-bold'>Programador Mercenario</span>, Prototipador Python, PHP (Codeigniter, Symfony), plugins para Wordpress, Java (Springboot), ReactJS y Administrador de Sistemas Posix/Linux y Containers Docker. CEO del Holding <a href="https://web.decontrabando.cl/">DeContrabando.cl</a>. Puedes pasar por <a href="https://github.com/edokope4" target="_blank" rel="noopener noreferrer" className="font-bold hover:underline">GitHub</a> o <a href="https://www.linkedin.com/in/eduardo-pe%C3%B1a-ceballos-60019148/" target="_blank" rel="noopener noreferrer" className="font-bold hover:underline">LinkedIn</a>.</p>

                        <p className='p-1'>Aprendiz de carpintero, constructor y jardinero. Estoy en aprendizaje constante, lo cuál me hace fallar, ajustar y volver a cagarla.</p>

                        <p className='p-1'>Aficionado al estudio de las Artes Marciales Japonesas. Estudiante en <span className='font-bold'>Raijin Dojo</span> (Bujinkan) y <span className='font-bold'>Club de Judo Eslabón</span>.</p>

                        <p className="mt-3 italic text-gray-400 text-left">Desayuno perfecto: Barros Luco con Tomate.</p>

                        <p className="mt-3 font-mono text-yellow-500">Donaciones BTC: bc1qe8w30mk2h8w0n48zsy69md95hd0hjjkvch7zgw</p>
                    </div>
                    <div className='py-6 text-center'>
                        <a 
                            href="/wishlist" 
                            className="py-3 mt-3 font-mono text-blue-800 bg-gray-100 hover:bg-gray-400 px-4 py-2 rounded-lg shadow-md transition duration-100"
                        >¿Quieres hacerme un regalo?</a>
                    </div>
                </div>
                {/* Div vacío para separar contenido */}
                <div className="h-6"></div>
            </div>
        )
    }

}
