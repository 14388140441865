import React, { Component } from 'react';
import './Footer.css';

export default class FooterCredits extends Component {
    render() {
        return (
        <div>
            <div className='w-full h-2'></div>
                <div className="footer px-6 py-1 text-gray-400">
                    <div className="">
                        {/* Columna izquierda */}
                        <div className="font-bold text-gray">
                            <p>kope.cl &reg; 2006 - 2024</p>
                        </div>
                    </div>
                </div>
            <div className='w-full h-4'></div>
        </div>
        );
    }
}
