import React, { Component } from "react";
import axios from 'axios';
import Title from '../Layout/Title';

export default class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            title: 'Login',
            isError: false,
            errorMsg: null
        };
        
        // Enlazar los métodos a `this`
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    };

    async handleSubmit (e) {
        e.preventDefault();
        const { username, password } = this.state;
        const url = 'https://api.kope.cl/auth/login';
        
        try {
            const response = await axios.post(url, {
                username: username,
                password: password
            });
            
            if (response.data.code === "200") {
                console.log("respuesta codigo 200");
                const { token } = response.data.data;
                localStorage.setItem('authToken', token);

                if (!localStorage.getItem('restrictedPath')) {
                    window.location.href = '/';
                } else {
                    window.location.href = localStorage.getItem('restrictedPath');
                }
            } else if (response.data.code === 403) {
                this.setState({ 
                    isError: true,
                    errorMsg: response.data.errorMessage,
                    username: "",
                    password: ""
                 });
            } else {
                console.error('Código de respuesta inesperado:', response.data.code);
            }
        } catch (error) {
            if (error.response) {
                console.error('Respuesta del servidor:', error.response.data);
            } else if (error.request) {
                console.error('No se recibió respuesta del servidor:', error.request);
            } else {
                console.error('Error:', error.message);
            }
        }
    };

    render() {
        return (
            <div>
            <div className="flex items-center justify-center bg-opacity-90 bg-gray-900  border border-gray-900 text-gray-300 p-6 rounded-lg shadow-lg ">
                <div className="p-6 rounded shadow-md w-full max-w-md">
                    <Title dataFromParent={this.state.title} />
                    <div className={`text-red-500 bg-gray-100 text-right ${this.state.isError ? '' : 'hidden'}`}>{this.state.errorMsg ? this.state.errorMsg : null}</div>
                    <form onSubmit={this.handleSubmit} className="space-y-4">
                        <div className="form-group">
                            <label htmlFor="username" className="block text-sm font-medium text-gray-100">Usuario:</label>
                            <input 
                                type="text"
                                id="username"
                                name="username"
                                value={this.state.username}
                                onChange={this.handleChange}
                                required
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />  
                        </div>
                        <div className="form-group">
                            <label htmlFor="password" className="block text-sm font-medium text-gray-100">Contraseña:</label>
                            <input 
                                type="password"
                                id="password"
                                name="password"
                                value={this.state.password}
                                onChange={this.handleChange}
                                required
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />  
                        </div>
                        <button type="submit" className="w-full py-2 px-4 bg-indigo-600 text-white rounded-md shadow-sm hover:bg-indigo-700">Iniciar sesión</button>
                    </form>
                </div>
            </div>
            <div className='w-full h-4'></div>
            </div>
          );
    };
}
