import './Menu.css'

import React, { Component } from 'react';

export default class Menu extends Component {
    render (){
        return (
            <div className="menu">
                <div className='menu-boder'></div>
                <button-group>
                    <button
                        className="kp-button"
                        type="button"
                        onClick={(e) => {
                            e.preventDefault();
                            window.location.href='/';
                        }}>Inicio</button>
                        <button
                        className="kp-button"
                        type="button"
                        onClick={(e) => {
                            e.preventDefault();
                            window.location.href='/whoami';
                        }}>¿kopE?</button>
                        <button
                        className="kp-button"
                        type="button"
                        onClick={(e) => {
                            e.preventDefault();
                            window.location.href='/blog';
                        }}> Blog</button>
                        <button
                        className="kp-button"
                        type="button"
                        onClick={(e) => {
                            e.preventDefault();
                            window.location.href='/photos';
                        }}> Fotos</button>
                </button-group>
                {localStorage.getItem("authToken") != null && (
                    <button type="button"
                        className="kp-button"
                        onClick={(e) => {
                            e.preventDefault();
                            localStorage.setItem('authToken', null);
                            window.location.href='/logout';
                        }}>X</button>
                )}
            </div>
        )
    }
}