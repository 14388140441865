import React, { Component } from 'react';
import './Blog.css';
import axios from 'axios'
import Title from '../Layout/Title';
import { Link } from 'react-router-dom';

export default class Blog extends Component {
    state = {
        'posts': [],
        'title': "tail -f /var/log/kope.log"
    }
    
    Blog() {
    }

    componentDidMount() {
        const obtenerData = async() => {
            const url = 'https://api.kope.cl/getBlog';
            await axios.get( url )
                .then( (response) => {
                    this.setState({
                        "posts": response.data.data.posts
                    });
                })
                .catch( error => {
                    console.log("=( NO conecta con API");
                    console.log(error);
                });
        }
        obtenerData();
    }

    render () {
        return (
            <div>
                <div className="blogContainer">
                    <Title dataFromParent={this.state.title} />
                    <div className=''>
                        {
                        this.state.posts.map((post) => (
                            <div>
                                <div key={post.id} className="bg-opacity-90 bg-gray-900 border border-gray-900 text-gray-300 p-6 rounded-lg shadow-lg max-w-2xl mx-auto">
                                    <div className="postTitle p-6">
                                        <Link to={`blog/post/${post.id}`}>{post.title}</Link>
                                    </div>
                                    <div className="postContent " dangerouslySetInnerHTML={{ __html: post.content }}></div>
                                    <div className='postDate'>{post.created_at}</div>
                                </div>
                                <div className='w-full h-6'></div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        )
    }
}
